import React from 'react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { PageDisplayName } from '../../../app/components/DynamicPage/utils';
import { ConfirmCancelModalRoot } from '../../components/ConfirmCancelModalContext';
import {
  HeaderLayout,
  QueryBindSearchBar,
  type QueryBindSearchBarProps,
} from '../../components/Header';
import { HeaderAvatar } from '../../components/HeaderAvatar';
import { LPLogo } from '../../components/icons/LPLogo';
import { NotificationCenter } from '../../components/Notification';
import { DefaultPageProvider } from '../../components/PageProvider';
import config from '../../config';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { Header } from './Header';

const AdminHeaderMenu = ({
  gamePackImportEnabled = config.misc.gamePackImportEnabled,
}: {
  gamePackImportEnabled?: boolean;
}): JSX.Element => {
  const gamePackLinks = {
    'Game Packs': '/admin/gamepacks',
    'User Generated': '/admin/gamepacks/ugc',
    'Training Courses': '/admin/courses',
    'Free for Zoom': '/admin/gamepacks/zoom',
  };
  if (gamePackImportEnabled) {
    uncheckedIndexAccess_UNSAFE(gamePackLinks)['Import'] =
      '/admin/gamepacks/import';
  }

  const publicLibraryV2Links = [
    EnumsPageName.PageNameExplore,
    EnumsPageName.PageNamePublicHome,
    EnumsPageName.PageNameZoom,
    EnumsPageName.PageNameStaging,
    EnumsPageName.PageNameTrial,
    EnumsPageName.PageNameGeneralLearning,
    EnumsPageName.PageNameGeneralLearningStaging,
  ].reduce((acc, pageName) => {
    acc = {
      ...acc,
      [PageDisplayName(pageName)]: $path('/admin/public-library/:name', {
        name: pageName,
      }),
    };
    return acc;
  }, {});

  const links = {
    Brands: '/admin/brands',
    Minigames: '/admin/minigames',
    'Game Packs': gamePackLinks,
    'Public Library': publicLibraryV2Links,
    Organizations: {
      Organizations: '/admin/organizations',
      'Engagement Score Reports':
        '/admin/organizations/engagement-score-reports',
    },
    Users: '/admin/users',
    Programs: '/admin/programs',
    Toolkit: '/admin/toolkit',
  };

  return <Header links={links} />;
};

const AdminHeader = (props: {
  search?: QueryBindSearchBarProps;
}): JSX.Element => {
  const left = (
    <>
      <LPLogo type='admin' className='h-10 w-10' />
      <AdminHeaderMenu />
    </>
  );

  const right = (
    <>
      {props.search && <QueryBindSearchBar {...props.search} />}
      <HeaderAvatar />
    </>
  );

  return (
    <HeaderLayout fill left={left} right={right} bgClassName='bg-admin-red' />
  );
};

export const AdminView = (props: {
  search?: QueryBindSearchBarProps;
  children?: React.ReactNode;
  className?: string;
  notificationCenter?: boolean;
  contentKey?: string;
}): JSX.Element => {
  return (
    <DefaultPageProvider>
      <div className='w-full h-full bg-black flex flex-col'>
        <AdminHeader search={props.search} />
        <div
          key={props.contentKey}
          className={`flex-1 overflow-y-auto scrollbar ${props.className}`}
        >
          {props.children ?? null}
        </div>
      </div>
      <ConfirmCancelModalRoot />
      {props.notificationCenter && (
        <NotificationCenter
          className='!w-108'
          position='bottom-right'
          toastClassName='rounded my-2'
        />
      )}
    </DefaultPageProvider>
  );
};
